import { Link } from "react-router-dom";
import classNames from "./style.module.scss";
import { motion } from "framer-motion";
import { routes } from "../../contants/routes";

export const codeLines = {
  sectionOne: [
    <>
      <span className="text-bd">let</span>
      <span className="text-bl"> charitha</span> =
      <span className="text-bd"> new</span>
      <span className="text-gl"> Programmer</span>();
    </>,
  ],
  aboutMeLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> About me:"`}</span>);
    </>,
  ],
  aboutMeCmnt: [
    <span className="text-gd">{`// Hey there 👋. Welcome to my portfolio. My name is Charitha Goonewardena. 
    Based in Sri Lanka (island country lying in the Indian Ocean).
    So, I am a software engineer 👨‍💻 who loves frontend development.
    I like to work mainly with ReactJs ⚛️ and React Native.
    I have 4+ years of experience in frontend development 🖥️.
    Have a strong foundation in JS, front-end development 💅, and a bit of a UIUX.
    Also have a keen interest in IoT development ✨ as well (sort of a drug).
    PS: Programmer by day 🌞 and gamer by night 🌙.`}</span>,
  ],
  myStoryLink: [
    <span className="text-gd">{`// Wanna hear my story?`}</span>,
    <Link to={routes.MYSTORY}>
      <motion.div
        className={`${classNames.action}`}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <span className="text-y">readMyStory</span>
        <span className="text-w">
          ();
          <motion.div
            className="text-gd"
            initial={{
              y: -5,
            }}
            animate={{
              y: 3,
            }}
            transition={{
              type: "tween",
              ease: "easeInOut",
              repeat: Infinity,
              repeatType: "reverse",
              duration: 1,
            }}
          >
            <span className="text-gd">{`👆`}</span>
          </motion.div>
        </span>
      </motion.div>
    </Link>,
  ],
  mySotryLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> My story:"`}</span>);
    </>,
  ],
  mySotryCmnt: [
    <span className="text-gd">{`// Everyone has a story in their life so do I 🙈. 
    Mine is interesting (at least for me 😏), cause I failed so many times. `}</span>,
    <span className="text-gd">{`// ---- Here is my story ----`}</span>,
    <span className="text-gd">{`// As a kid, I loved technology and innovation like everybody else. 
    But I wanted to learn and make things unlike everybody else.
    So I created my first product Knight Rider (KITT) Scanner LED Light System. 
    I started my first business of selling this to my colleagues. 
    I was a happy kid, but I hated school. I almost failed all subjects.
    But I was passionate about tech.
    So I left school three years before my friends.
    And I wanted to learn programming.`}</span>,
  ],
  mySotryCmntMore: [
    <span className="text-gd">
      {`// So I started my under grad at the University of Westminster UK.
    I graduated with a first-class honors. 
    I was the batch 3rd. Proud moments? Nah.., it's just a certification (IDC).
    But it helped me to find a good job in my career at a tech company in Sri Lanka.
    I worked for a tech company that is listed under the top 3 IT companies in Sri Lanka.`}
    </span>,
    <span className="text-gd">
      {`// I was a frontend Software Engineer for this company for more than 3 years.
    And mostly I was working with Norwegian clients.
    But I felt doing something more than Software.
    So I started my own product.
    It is an IoT product.
    And it's a smart lighting tower for gamers and streamers.
    I left my job and went all in hoping that Covid19 will be gone away in few months.
    I left my job coz I wanted to focus fully on this product.
    `}
    </span>,
    <span className="text-gd">{`//Soo, I completed the prototype of the IoT device and the mobile application in under 3 to 4 months.
    Actual product is currently being developed at Xydder3d.lk.
    But it takes so much time with this Covid19 situation.
    Also hard to find supplies in here at Sri Lanka. Now feels a little stupid.
    Anyway parallelly I started to develop a smart collar for cats and dogs as well. 
    Since these things take the time I thought work as a freelance developer for one of my friend's companies.
    But seems like I have more time and can really work full time for a company.
    Moreover I am more comfortable with a corporate job.
    So I am searching for an opportunity in react and/or react native.`}</span>,
  ],
  whoAmILog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> Who am I really?"`}</span>);
    </>,
  ],
  yearsEx: [
    <>
      <span className="text-bl">charitha.yearsOfExperience</span> =
      <span className="text-gvl"> 4</span>;
    </>,
  ],
  techSkills: [
    <>
      <span className="text-bl">charitha.technicalSkills</span> = [
      <span className="text-br"> "js"</span>,
      <span className="text-br"> "react"</span>,
      <span className="text-br"> "redux"</span>,
      <span className="text-br"> "react-native"</span>,
      <span className="text-br"> "angular"</span>,
      <span className="text-br"> "flutter"</span>
      ];
    </>,
  ],
  designSkills: [
    <>
      <span className="text-bl">charitha.designSkills</span> = [
      <span className="text-br">"html"</span>,
      <span className="text-br"> "css"</span>,
      <span className="text-br"> "scss"</span>,
      <span className="text-br"> "bootstrap"</span>,
      <span className="text-br"> "ant-design"</span>];
    </>,
  ],
  personalSkills: [
    <>
      <span className="text-bl">charitha.personalSkills</span> = [ <br />
      &nbsp;&nbsp;<span className="text-br"> "willingness to learn"</span>,<br />
      &nbsp;&nbsp;<span className="text-br"> "team working"</span>,<br />
      &nbsp;&nbsp;<span className="text-br"> "problem solving"</span>,<br />
      &nbsp;&nbsp;<span className="text-br"> "interpersonal skills"</span>,
      <br />
      &nbsp;&nbsp;<span className="text-br"> "creativity"</span>
      <br />
      ]; <span className="text-gd">{`// just a few ;)`}</span>
    </>,
  ],
  certificates: [
    <>
      <span className="text-bl">charitha.certificates</span> = [ <br />
      &nbsp;&nbsp;{`{`} <br />
      &nbsp;&nbsp; &nbsp;&nbsp;<span className="text-bl">name</span>:
      <span className="text-br"> "Frontend Developer Nano Degree"</span>, <br />
      &nbsp;&nbsp; &nbsp;&nbsp;<span className="text-bl">url</span>:{" "}
      <span> </span>
      <a
        href="https://graduation.udacity.com/confirm/9LLJS5RD"
        className={`text-br anchor`}
        target="_blank"
        rel="noreferrer"
      >
        "https://graduation.udacity.com/confirm/9LLJS5RD"
      </a>
      , <br />
      &nbsp;&nbsp;{`},`} <br />
      &nbsp;&nbsp;{`{`} <br />
      &nbsp;&nbsp; &nbsp;&nbsp;<span className="text-bl">name</span>:
      <span className="text-br"> "React - The Complete Guide"</span>, <br />
      &nbsp;&nbsp; &nbsp;&nbsp;<span className="text-bl">url</span>:{" "}
      <span> </span>
      <a
        href="https://www.udemy.com/certificate/UC-4d549de8-484e-41f7-a973-7fc3802e0c94/"
        className={`text-br anchor`}
        target="_blank"
        rel="noreferrer"
      >
        "https://tinyurl.com/5d5v9jhk"
      </a>
      , <br />
      &nbsp;&nbsp;{`}`} <br />
      {`];`} <br />
    </>,
  ],
  dob: [
    <>
      <span className="text-bl">charitha.dob </span> =
      <span className="text-bl"> new </span>
      <span className="text-gl">Date</span>(
      <span className="text-gvl">1995</span>,
      <span className="text-gvl"> 09</span>,
      <span className="text-gvl"> 22</span>
      );
    </>,
  ],
  nationality: [
    <>
      <span className="text-bl">charitha.nationality </span> =
      <span className="text-br"> "Sri Lankan 💖"</span>;
    </>,
  ],
  intro: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> Introduce me in a simpler way:"`}</span>
      );
    </>,
  ],
  codeIntro: [
    <>
      <span className="text-y">while </span> (
      <span className="text-bd">charitha.alive</span>) {`{`} <br />
      &nbsp;&nbsp;
      <span className="text-bd">charitha.</span>
      <span className="text-y">wakeUp</span>(); <br />
      &nbsp;&nbsp;
      <span className="text-bd">charitha.</span>
      <span className="text-y">eat</span>(); <br />
      &nbsp;&nbsp;
      <span className="text-bd">charitha.</span>
      <span className="text-y">code</span>(); <br />
      &nbsp;&nbsp;
      <span className="text-bd">charitha.</span>
      <span className="text-y">game</span>(); <br />
      &nbsp;&nbsp;
      <span className="text-bd">charitha.</span>
      <span className="text-y">sleep</span>(); <br />
      &nbsp;&nbsp;
      <span className="text-bd">charitha.</span>
      <span className="text-y">dream</span>(); <br />
      {`}`}
    </>,
  ],
};
