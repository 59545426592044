import { useEffect, useRef, useState } from "react";
import { motion, useCycle } from "framer-motion";
import { useDimensions } from "../../helpers/use-dimensions";
import Nav from "./Nav";
import NavToggle from "./NavToggle";
import classNames from "./style.module.scss";

const sidebar = {
  open: (height = 1000) => ({
    clipPath: `circle(${height * 2 + 200}px at 40px 40px)`,
    transition: {
      type: "spring",
      stiffness: 20,
      restDelta: 2,
    },
  }),
  closed: {
    clipPath: "circle(30px at 40px 40px)",
    transition: {
      type: "spring",
      stiffness: 400,
      damping: 40,
    },
  },
};

export default function Navigation() {
  const [width, setWidth] = useState(window.innerWidth);
  let isMobile = width <= 768;
  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  const { height } = useDimensions(containerRef);

  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    setTimeout(() => {
      if (!isMobile) toggleOpen();
    }, 0);
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
    // eslint-disable-next-line
  }, [isMobile]);

  return (
    <motion.nav
      initial={false}
      animate={isOpen ? "open" : "closed"}
      custom={height}
      ref={containerRef}
    >
      <motion.div className={classNames.navBackground} variants={sidebar} />
      {isOpen ? <Nav toggle={toggleOpen} /> : null}
      {isMobile ? <NavToggle toggle={() => toggleOpen()} /> : null}
    </motion.nav>
  );
}
