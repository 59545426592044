import { motion } from "framer-motion";
import Page from "../../components/Page";
import { codeLines } from "./codeLinesLeisure";

import classNames from "./style.module.scss";
import board from "../../assets/imgs/leisure/board.jpg";
import onboard from "../../assets/imgs/leisure/onboard.jpg";
import proto from "../../assets/imgs/leisure/proto.jpg";
import app1 from "../../assets/imgs/leisure/apps1.png";
import app2 from "../../assets/imgs/leisure/apps2.png";
import Lightbox from "react-awesome-lightbox";
import { useState } from "react";

const images = [
  {
    url: board,
    title: "ESP8266 with jumper wires",
    index: 0,
  },
  {
    url: onboard,
    title: "Dev board with mic component",
    index: 1,
  },
  {
    url: proto,
    title: "Working prototype",
    index: 2,
  },
  {
    url: app1,
    title: "Color picker in app",
    index: 3,
  },
  {
    url: app2,
    title: "Various Effects",
    index: 4,
  },
];

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default function Leisure() {
  const [showLightBox, setShowLightBox] = useState(false);
  const [imgIndx, setImgIndx] = useState(0);

  const handleShowImg = (indx) => {
    setImgIndx(indx);
    setShowLightBox(true);
  };

  return (
    <Page title="Leisure">
      {codeLines.myLeisureLog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.thankCmnt.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.thankLog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure1.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure2.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure3.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.vibelytsLog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.url.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure4.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure5.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure6.map((itm, i) => (
        <motion.p
          className="code-line"
          key={i}
          variants={item}
          style={{ marginBottom: 0 }}
        >
          {itm}
        </motion.p>
      ))}
      {codeLines.leisure7.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}

      <motion.div className={classNames.imageList} variants={item}>
        <section className={classNames.topImages}>
          <motion.img
            src={board}
            alt="board"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleShowImg(0)}
            style={{ height: showLightBox ? "auto" : "100%" }}
          />
          <motion.img
            src={onboard}
            alt="onboard"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleShowImg(1)}
            style={{ height: showLightBox ? "auto" : "100%" }}
          />
        </section>
        <section className={classNames.botImages}>
          <motion.img
            src={proto}
            alt="proto"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleShowImg(2)}
          />
          <motion.img
            src={app1}
            alt="app screenshot"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleShowImg(3)}
          />
          <motion.img
            src={app2}
            alt="app screenshot 2"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            onClick={() => handleShowImg(4)}
          />
        </section>
        {showLightBox ? (
          <Lightbox
            images={images}
            startIndex={imgIndx}
            onClose={() => {
              setShowLightBox(false);
            }}
          />
        ) : null}
      </motion.div>
      <section className={classNames.feats}>
        {codeLines.leisure8.map((itm, i) => (
          <motion.p className="code-line" key={i} variants={item} style={{marginBottom: 0}}>
            {itm}
          </motion.p>
        ))}
      </section>
    </Page>
  );
}
