import cardiac from "../../assets/imgs/myWork/cardiac.png";
import vibelyts from "../../assets/imgs/myWork/vibelyts.png";
import softrig from "../../assets/imgs/myWork/softrig.png";
import libt from "../../assets/imgs/myWork/libt.png";
import marketplace from "../../assets/imgs/myWork/marketplace.png";
import unieconomy from "../../assets/imgs/myWork/unieconomy.png";
import skeleton from "../../assets/imgs/myWork/skeleton.png";
import repo from "../../assets/imgs/myWork/repo.png";
import todo from "../../assets/imgs/myWork/todo.png";
import dynamic from "../../assets/imgs/myWork/dynamic.png";
import blog from "../../assets/imgs/myWork/blog.png";
import aegon from "../../assets/imgs/myWork/aegon.png";
import dsystem from "../../assets/imgs/myWork/dsystem.png";
import alphalee from "../../assets/imgs/myWork/alphalee.png";
import weather from "../../assets/imgs/myWork/weather.png";
import salon2 from "../../assets/imgs/myWork/salon2.png";
import salon1 from "../../assets/imgs/myWork/salon1.png";
import blogClean from "../../assets/imgs/myWork/blogClean.png";
import property from "../../assets/imgs/myWork/property.png";

const images = {
  cardiac,
  vibelyts,
  softrig,
  libt,
  marketplace,
  unieconomy,
  skeleton,
  repo,
  todo,
  dynamic,
  blog,
  aegon,
  dsystem,
  alphalee,
  weather,
  salon2,
  salon1,
  blogClean,
  property
};
export default images;