import { motion } from "framer-motion";
import github from "../../assets/svgs/github.svg";
import linkedin from "../../assets/svgs/linkedin.svg";
// import mail from "../../assets/svgs/mail.svg";
import twitter from "../../assets/svgs/twitter.svg";
import classNames from "./style.module.scss";

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.3,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default function Socials() {
  return (
    <div className={classNames.socialWrapper}>
      <motion.ul
        variants={container}
        initial="hidden"
        animate="visible"
        className={classNames.list}
      >
        {items.map((imgItem, index) => (
          <motion.li
            key={index}
            className="item"
            variants={item}
            whileHover={{ scale: 1.3 }}
            whileTap={{ scale: 0.95 }}
          >
            <a href={imgItem.path} target="_blank" rel="noreferrer">
              <figure key={index} variants={item}>
                {imgItem.comp}
              </figure>
            </a>
          </motion.li>
        ))}
      </motion.ul>
      <p
        className={`text-gvl ${classNames.mail}`}
      >{` charitha.exe@gmail.com`}</p>
    </div>
  );
}
const items = [
  {
    path: "https://github.com/charitha95",
    comp: <img src={github} alt="github" />,
  },
  {
    path: "https://www.linkedin.com/in/charitha-g/",
    comp: <img src={linkedin} alt="linkedin" />,
  },
  {
    path: "https://twitter.com/charithaGG",
    comp: <img src={twitter} alt="twitter" />,
  },
];
