import JotformEmbed from "react-jotform-embed";
import Page from "../../components/Page";

export default function Contact() {
  return (
    <Page title="Contact">
      <span className="text-gd">{`// contact me via email:`}</span>
      <span className="text-y">{` charitha.exe@gmail.com`}</span>
      <JotformEmbed src="https://form.jotform.com/211460929916057" />
    </Page>
  );
}
