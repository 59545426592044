import { motion } from "framer-motion";
import MyWorkItem from "../../components/MyWorkComps/MyWorkItem";
import Page from "../../components/Page";
import images from "./images";
import classNames from "./style.module.scss";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default function MyWork() {
  return (
    <Page title="MyWork">
      <motion.p className={`code-line ${classNames.line}`} variants={item}>
        <span className="text-bl">console</span>.
        <span className="text-y">log</span>(
        <span className="text-br"> {`">>> Mobile Apps:"`}</span>);
      </motion.p>
      {myWorkItems.mobileApps.map((item) => (
        <MyWorkItem
          key={item.title}
          itemPic={item.image}
          title={item.title}
          tech={item.tech}
          info={item.info}
          stack={item.stack}
          link={item.link}
        />
      ))}

      <motion.p
        className={`code-line ${classNames.line}`}
        variants={item}
        style={{ marginTop: "55px" }}
      >
        <span className="text-bl">console</span>.
        <span className="text-y">log</span>(
        <span className="text-br"> {`">>> Web Apps:"`}</span>);
      </motion.p>
      {myWorkItems.webApps.map((item) => (
        <MyWorkItem
          key={item.title}
          itemPic={item.image}
          title={item.title}
          tech={item.tech}
          info={item.info}
          stack={item.stack}
          link={item.link}
        />
      ))}

      <motion.p
        className={`code-line ${classNames.line}`}
        variants={item}
        style={{ marginTop: "55px" }}
      >
        <span className="text-bl">console</span>.
        <span className="text-y">log</span>(
        <span className="text-br">
          {" "}
          {`">>>  Opensource/Experimental Apps:"`}
        </span>
        );
      </motion.p>
      {myWorkItems.openSource.map((item) => (
        <MyWorkItem
          key={item.title}
          itemPic={item.image}
          title={item.title}
          tech={item.tech}
          info={item.info}
          stack={item.stack}
          link={item.link}
        />
      ))}

      <motion.p
        className={`code-line ${classNames.line}`}
        variants={item}
        style={{ marginTop: "55px" }}
      >
        <span className="text-bl">console</span>.
        <span className="text-y">log</span>(
        <span className="text-br"> {`">>> Some of my designs:"`}</span>
        );
      </motion.p>
      {myWorkItems.designs.map((item) => (
        <MyWorkItem
          key={item.title}
          itemPic={item.image}
          title={item.title}
          tech={item.tech}
          info={item.info}
          stack={item.stack}
          link={item.link}
        />
      ))}
    </Page>
  );
}

const myWorkItems = {
  mobileApps: [
    {
      image: images.vibelyts,
      title: "Vibelyts",
      tech: "React Native EXPO",
      info: "Vibelyts is an app to manage your smart lighting devices. Currently, this app supports Glow Bar.",
      stack: ["react-native", "redux", "expo"],
      link: "https://apps.apple.com/in/app/vibelyts/id1563414383",
    },
    // {
    //   image: images.cardiac,
    //   title: "the cardiac centre",
    //   tech: "React Native CLI",
    //   info: "The cardiac centre is a mobile application developed for a health care center based in Australia. ",
    //   stack: ["react-native-cli", "redux", "context-api"],
    //   link: "https://www.behance.net/gallery/120274449/Health-care-app",
    // },
  ],
  webApps: [
    {
      image: images.softrig,
      title: "SoftRig developer",
      tech: "React",
      info: "SoftRig developer portal is for developers to find out more on SoftRig REST API and other useful documentations. ",
      stack: ["react", "redux", "react-reveal"],
      link: "https://developer.softrig.com/",
    },
    {
      image: images.libt,
      title: "LIBT",
      tech: "React",
      info: "LIBT stands for London Institute of Business and Technology. It's the world's first pay-as-you-go, College-as-a-Service Platform",
      stack: ["react", "redux", "react-bootstrap"],
      link: "https://libt.co.uk/",
    },
    {
      image: images.marketplace,
      title: "UNIECONOMY MARKETPLACE",
      tech: "React",
      info: "To show the existing wide ecosystem of partners and their products to current and potential customers. ",
      stack: ["react", "redux", "react-bootstrap"],
      link: "https://dev-marketplace.unieconomy.no/",
    },
    {
      image: images.unieconomy,
      title: "UNIECONOMY ERP SYSTEM",
      tech: "Angular",
      info: "UniEconomy is a 100% cloud based ERP system mainly focuses on the accounting, payments, sales and hours. ",
      stack: ["angular", "bootstrap", "scss", "chart.js"],
      link: "https://unieconomy.no/#/",
    },
  ],
  openSource: [
    {
      image: images.skeleton,
      title: "skeleton loader",
      tech: "React",
      info: "Javascript plugin for skeleton loading effect. This effect is similar to the Facebook loading effect.",
      stack: ["javascript", "npm"],
      link: "https://react-skeleton-loading.netlify.app/",
    },
    {
      image: images.repo,
      title: "repo finder",
      tech: "React",
      info: "CRA application implemented to find available repos based on a given GIT user name. Uses a custom state management system.",
      stack: ["react", "scss"],
      link: "https://repofindercg.netlify.app/",
    },
    {
      image: images.todo,
      title: "TODO APP",
      tech: "React",
      info: "Experimental react todo app which uses Ant Design as a UI library with Tailwind utility-driven css framework",
      stack: ["react", "ant-design", "tailwind-css"],
      link: "https://react-todo-app-cg.netlify.app/",
    },
    {
      image: images.dynamic,
      title: "Dynamic Views with pure Js ",
      tech: "JavaScript",
      info: "A cross-browser solution to target Chrome and Firefox which uses only native JavaScript(ES6), CSS3 and HTML5.",
      stack: ["javascript", "html", "css"],
      link: "https://dynamic-views-with-vanilla-js.netlify.app/",
    },
    {
      image: images.blog,
      title: "personal blog template",
      tech: "html",
      info: "A template for a personal blog with proper CSS and HTML formatting & style. Main layout of the template is on css grid.",
      stack: ["css-grid", "html", "css"],
      link: "https://personal-blog-template.netlify.app/",
    },
  ],
  designs: [
    {
      image: images.aegon,
      title: "Futureready - aegon",
      tech: "adobeXD",
      info: "Design and solution for a insurance company based in Thailand and Netherland.",
      stack: ["design", "UI", "UX", "adobeXD"],
      link: "https://www.behance.net/gallery/120277707/Insurance-company-web-app",
    },
    {
      image: images.dsystem,
      title: "Unieconomy - Design system",
      tech: "adobeXD",
      info: "Proposed design for the design system of unieconomy.",
      stack: ["design", "system", "UX"],
      link: "https://www.behance.net/gallery/120277337/Design-language-system",
    },
    {
      image: images.alphalee,
      title: "Alphalee",
      tech: "adobeXD",
      info: "Proposed design for the alphalee fitness center.",
      stack: ["design", "fitness", "UIUX"],
      link: "https://www.behance.net/gallery/120277541/Fitness-Center-App",
    },
    // {
    //   image: images.weather,
    //   title: "Weather app",
    //   tech: "adobeXD",
    //   info: "Experimental simple design for a whether app.",
    //   stack: ["design", "UI", "UX", "adobeXD"],
    //   link: "https://libt.co.uk/",
    // },
    {
      image: images.salon2,
      title: "Salon app v2",
      tech: "adobeXD",
      info: "Experimental simple design for a salon application.",
      stack: ["design", "UI", "UX", "adobeXD"],
      link: "https://www.behance.net/gallery/120277019/Salon-App-UI",
    },
    {
      image: images.salon1,
      title: "Salon app v1",
      tech: "adobeXD",
      info: "Experimental simple design for a salon application.",
      stack: ["design", "salon", "UX"],
      link: "https://www.behance.net/gallery/120277019/Salon-App-UI",
    },
    {
      image: images.blogClean,
      title: "Blog clean design",
      tech: "adobeXD",
      info: "Experimental simple design for a blog website.",
      stack: ["clean", "blog", "UX", "adobeXD"],
      link: "https://www.behance.net/gallery/97295663/Clean-and-modern-blog-template",
    },
    {
      image: images.property,
      title: "Property advertisement web",
      tech: "adobeXD",
      info: "Experimental simple design for a property advertisement website.",
      stack: ["website", "web UI"],
      link: "https://www.behance.net/gallery/120278295/Property-advertisement-web-application-UI",
    },
  ],
};
