import { motion } from "framer-motion";
import { codeLines } from "./codeLines";
import Page from "../../components/Page";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};


export default function Home() {
  return (
    <Page title="Home">
      {codeLines.sectionOne.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.aboutMeLog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.aboutMeCmnt.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.myStoryLink.map((itm, i) => (
        <motion.section
          className="code-line"
          style={
            codeLines.myStoryLink.length - 1 !== i ? { marginBottom: 0 } : {}
          }
          key={i}
          variants={item}
        >
          {itm}
        </motion.section>
      ))}

      {codeLines.whoAmILog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}

      {codeLines.techSkills.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.designSkills.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.personalSkills.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.certificates.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.dob.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.nationality.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.intro.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.codeIntro.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
    </Page>
  );
}
