export const codeLinesEx = {
  exp: [
    <>
      <span className="text-bl">charitha.experience</span> = [ <br />
    </>,
     <>
     &nbsp;&nbsp;{`{`} <br />
     &nbsp;&nbsp; &nbsp;&nbsp;
     <span className="text-bl">companyName</span>:
     <span className="text-br"> "DevUpLink"</span>, <br />
     &nbsp;&nbsp; &nbsp;&nbsp;
     <span className="text-bl">designation</span>:
     <span className="text-br"> "Senior Software Engineer" </span>,<br />
     &nbsp;&nbsp; &nbsp;&nbsp;
     <span className="text-bl">period</span>:
     <span className="text-br"> "2021 Jun - Present"</span>,
     <br />
     &nbsp;&nbsp;{`}`}, <br />
   </>,
    <>
      &nbsp;&nbsp;{`{`} <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">companyName</span>:
      <span className="text-br"> "Vibelyts"</span>, <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">designation</span>:
      <span className="text-br"> "Indie Developer" </span>,<br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">period</span>:
      <span className="text-br"> "2020 Oct - 2021 Jun"</span>,
      <br />
      &nbsp;&nbsp;{`}`}, <br />
    </>,
    <>
      &nbsp;&nbsp;{`{`} <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">companyName</span>:
      <span className="text-br"> "99x.io"</span>, <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">designation</span>:
      <span className="text-br"> "Senior Software Engineer - Frontend" </span>,
      <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">period</span>:
      <span className="text-br"> "2020 june - 2020 oct"</span>,
      <br />
      &nbsp;&nbsp;{`}`}, <br />
    </>,
    <>
      &nbsp;&nbsp;{`{`} <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">companyName</span>:
      <span className="text-br"> "99x Technology"</span>, <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">designation</span>:
      <span className="text-br"> "Software Engineer - Frontend" </span>,<br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">period</span>:
      <span className="text-br"> "2017 july - 2020 july"</span>,
      <br />
      &nbsp;&nbsp;{`}`}, <br />
    </>,
    <>
      &nbsp;&nbsp;{`{`} <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">companyName</span>:
      <span className="text-br"> "Creo360"</span>, <br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">designation</span>:
      <span className="text-br"> "Trainee Software Engineer - Frontend" </span>
      ,<br />
      &nbsp;&nbsp; &nbsp;&nbsp;
      <span className="text-bl">period</span>:
      <span className="text-br"> "2015 - 2016 "</span>,
      <br />
      &nbsp;&nbsp;{`}`}, <br />
    </>,
    <>
      {`];`} <br />
    </>,
  ],
  expLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> Experience:"`}</span>);
    </>,
  ],
  expComnt: [
    <span className="text-gd">
      {`// I started industry-level programming back in 2015 with my internship. 
    At that time I was working as a frontend developer (intern) who contributed in web and mobile.`}
    </span>,
    <span className="text-y">{`/* Tech Stack:: Angular Js, Onsen UI, Apache Cordova, Materialize CSS */`}</span>,
  ],
  expComntMore: [
    <span className="text-gd">
      {`// After my internship, I joined 99x technology after completing the degree program. 
      I joined as a Full Stack Developer in 2017 and I got the opportunity to work as a full-stack developer for few months before choosing a career path. So after few months of full-stack development 
      I choose to go with my passion which is frontend development. Then later I got promoted to Senior 
      Software Engineer position in the same company (rebranded to .io).`}
    </span>,
    <span className="text-y">{`/* Tech Stack:: React, Redux, Hooks, Context API, Scss, Angular, Ant Design, Bootstrap, and many more */`}</span>,
  ],
  expComntMy: [
    <span className="text-gd">
      {`// At the end of 2020, I decided to leave 99x.io and start my own product under a hypothetical company name called 'VibeLyts'.
       I was the only one working on this. I completed my first product's prototype a few weeks back. Also Completed the respective 
       mobile app and it's live on both the app store and play store. Just Search for Vibelyts`}
    </span>,
    <span className="text-y">{`/* Tech Stack:: React, Redux, Hooks, Context API, React Native CLI, React Native EXPO, Arduino, ESP8266 */`}</span>,
  ],
};
