import Navigation from "../Navigation";
// import RecentProjectsAside from "../RecentProjectsAside";
// import Socials from "../Socials";
// import classNames from "./style.module.scss";

export default function Aside() {
  return (
    <>
      <Navigation />
      {/* <section className={classNames.asideStatic}>
        <RecentProjectsAside />
        <Socials />
      </section> */}
    </>
  );
}
