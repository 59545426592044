import Page from "../../components/Page";
import { Link } from "react-router-dom";
import classNames from "./style.module.scss";
import { motion } from "framer-motion";

export default function Resume() {
  return (
    <Page title="Resume">
      <span className="text-gd">{`// Please click on the below button to download my resume.`}</span>
      <motion.div
        className={classNames.download}
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
      >
        <Link to="/files/FrontendEngineerCharithaCV.pdf" target="_blank" download>
          download();
        </Link>
      </motion.div>
    </Page>
  );
}
