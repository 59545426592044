import { AnimatePresence } from "framer-motion";
import { Switch, Route } from "react-router-dom";

import { routes } from "../../contants/routes";
import Contact from "../../pages/Contact";
import Experience from "../../pages/Experience";
import Home from "../../pages/Home";
import Leisure from "../../pages/Leisure";
import MyStory from "../../pages/MyStory";
import MyWork from "../../pages/MyWork";
import Resume from "../../pages/Resume";
import Aside from "../Aside";
import classNames from "./style.module.scss";

export default function Layout() {
  return (
    <div className={classNames.layout}>
      <div className={classNames.container}>
        <aside>
          <Aside />
        </aside>
        <main>
          <AnimatePresence>
            <Switch>
              <Route path={routes.HOME}>
                <Home />
              </Route>
              <Route path={routes.EXPERIENCE}>
                <Experience />
              </Route>
              <Route path={routes.MYWORK}>
                <MyWork />
              </Route>
              <Route path={routes.MYSTORY}>
                <MyStory />
              </Route>
              <Route path={routes.LEISURE}>
                <Leisure />
              </Route>
              <Route path={routes.CONTACT}>
                <Contact />
              </Route>
              <Route path={routes.RESUME}>
                <Resume />
              </Route>
              <Route path="/">
                <Home />
              </Route>
            </Switch>
          </AnimatePresence>
        </main>
      </div>
    </div>
  );
}
