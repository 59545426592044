export const codeLines = {
  myLeisureLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> My leisure:"`}</span>);
    </>,
  ],
  thankCmnt: [
    <span className="text-gd">{`// Damn... You are great ! Before talking about my leisure let me thank you for showing 
    interest in my website 🙏. This is awesome. Let me log it for you 😊.`}</span>,
  ],
  thankLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`"** THANK YOU **"`}</span>);
    </>,
  ],
  leisure1: [
    <span className="text-gd">{`// Okay so you wanted to know what I do in my leisure time right? A lot. 
    I do so many things in my leisure time. Mostly I do create/learn simple web related things. 
    Even though I am frontend developer I do other things apart from that. 
    I have a keen interest in building IoT products. I am so addicted to this and that's why I 
    left my day job and jumped right into the IoT development thinking that I could sell my product one day, 
    though I am stuck in middle of it, but I am super happy.`}</span>,
  ],
  leisure2: [
    <span className="text-gd">{`// So talking about IoT, I started it without knowing anything about it. 
    But now I am in a position where I can understand how hardware and software work together. 
    It's not that hard to grasp the fundamentals. Because internet is your friend! Just ask it. Hahaha!`}</span>,
  ],
  leisure3: [
    <span className="text-gd">{`// Anyway You can find out simple things I tried to implement and how far I have come.`}</span>,
  ],
  vibelytsLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`"Vibelyts - Glow Bar"`}</span>);
    </>,
  ],
  url: [
    <>
      <span className="text-bl">url</span>(
      <span className="text-br">
        <a className="text-br anchor" 
         target="_blank"
         rel="noreferrer"
         href="https://youtu.be/HHkBhmdTOM8">{`"https://youtu.be/HHkBhmdTOM8"`}</a>
      </span>
      );
    </>,
  ],
  leisure4: [
    <span className="text-gd">{`// Please visit the above link to find out more about the product.`}</span>,
  ],
  leisure5: [
    <span className="text-gd">{`// This is the first IoT product I started to implement after leaving the job. 
    It is a smart light for gamers and streamers. Users of this product can control the light from their mobile using 
    the mobile application I developed. Works on both Android and iOS since I developed it using React Native.`}</span>,
  ],
  leisure6: [
    <span className="text-gd">{`// About the app and product:`}</span>,
  ],
  leisure7: [
    <span className="text-gd">{`// Vibelyts is an app to manage your smart lighting devices. 
    Currently, this app supports Glow Bar and Glow Triangles devices and will be able to integrate with 
    any new devices from the Vibelyts family that need no hub`}</span>,
  ],
  leisure8: [
    <span className="text-gd">{`// Features:`}</span>,
    <span className="text-gd">{`/*`} Unique features:</span>,
    <span className="text-gd">- Alexa, Siri, and Apple Home kit support.</span>,
    <span className="text-gd">- Schedule timers (with Alexa routines).</span>,
    <span className="text-gd">- Works without a hub.</span>,
    <span className="text-gd">- 50+ lighting effects and counting.</span>,
    <span className="text-gd">
      - 15+ Rhythm effects (music visualizer) and counting.
    </span>,
    <span className="text-gd">
      - Sound reactive lighting effects (Rhythm mode).
    </span>,
    <span className="text-gd">
      - Customization of over 16 million color options.
    </span>,
    <span className="text-gd">Basics features:</span>,
    <span className="text-gd">
      - On/off, next/previous, save, change modes/colors, change
      brightness/speed.
    </span>,
    <span className="text-gd">- Control over WiFi.</span>,
    <span className="text-gd">
      - Also could control from anywhere in the world (needs an Alexa).
    </span>,
    <span className="text-gd">- User profile with recently used effects.</span>,
    <span className="text-gd">
      - Check the status of the device in real-time.
    </span>,
    <span className="text-gd">- Connect new devices in seconds.</span>,
    <span className="text-gd">
      - Add unlimited multi-color patterns and colors.
    </span>,
    <span className="text-gd">
      - Save your favorite effects and patterns. {`*/`}
    </span>,
  ],
};
