import { motion } from "framer-motion";
import { codeLines } from "./codeLinesStory";
import Page from "../../components/Page";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};


export default function MyStory() {
  return (
    <Page title="MyStory">
      {codeLines.mySotryLog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.mySotryCmnt.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLines.mySotryCmntMore.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
    </Page>
  );
}
