import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";

import { routes } from "../../contants/routes";
import Socials from "../Socials";
import classNames from "./style.module.scss";

const variantsItem = {
  open: {
    y: 0,
    opacity: 1,
    display: "block",
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
    display: "none",
  },
};

const variantWrapper = {
  open: {
    transition: { delay: 1 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

export default function Nav({ toggle }) {
  const [width, setWidth] = useState(window.innerWidth);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  let isMobile = width <= 768;
  const { pathname } = useLocation();
  return (
    <>
      {!isMobile ? 
      <section className={classNames.socials}>
        <Socials/>
      </section> : null}
      <motion.div variants={variantWrapper} className={classNames.navWrapper}>
        <span>
          <span className="text-bd">function</span>
          <span className="text-y"> Navigation </span>
          <span className="text-tag">{`() {`}</span>
        </span>
        <section className={classNames.leftBoarder}>
          <span className="text-p">return</span>
          <span className="text-tag"> (</span>
          <section className={classNames.leftBoarder}>
            <span className="text-tag">{`<`}</span>
            <span className="text-bd">{`nav`}</span>
            <span className="text-tag">{`>`}</span>
            <section className={classNames.leftBoarder}>
              <span className="text-tag">{`<`}</span>
              <span className="text-bd">{`ul`}</span>
              <span className="text-tag">{`>`}</span>

              {routeItems.map((i) => (
                <section className={classNames.leftBoarder} key={i.path}>
                  <span className="text-tag">{`<`}</span>
                  <span className="text-bd">{`li`}</span>
                  <span className="text-tag">{`>`}</span>
                  <section className={classNames.leftBoarder}>
                    <Link to={i.path} onClick={isMobile ? toggle : null}>
                      <motion.span
                        variants={variantsItem}
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        style={{ width: "fit-content" }}
                      >
                        <span className="text-tag">{`</`}</span>
                        <span className="text-comp">{i.comp}</span>
                        {pathname === i.path ? (
                          <span className="text-bvl"> active</span>
                        ) : null}
                        <span className="text-tag">{`>`}</span>
                      </motion.span>
                    </Link>
                  </section>
                  <span className="text-tag">{`</`}</span>
                  <span className="text-bd">{`li`}</span>
                  <span className="text-tag">{`>`}</span>
                </section>
              ))}

              <span className="text-tag">{`</`}</span>
              <span className="text-bd">{`ul`}</span>
              <span className="text-tag">{`>`}</span>
            </section>
            <span className="text-tag">{`</`}</span>
            <span className="text-bd">{`nav`}</span>
            <span className="text-tag">{`>`}</span>
          </section>
          <span className="text-tag">{`);`}</span>
        </section>
        <span className="text-tag">{`}`}</span>
      </motion.div>
    </>
  );
}

const routeItems = [
  { path: routes.HOME, comp: "Home" },
  { path: routes.EXPERIENCE, comp: "Experience" },
  { path: routes.MYWORK, comp: "MyWork" },
  { path: routes.MYSTORY, comp: "MyStory" },
  { path: routes.LEISURE, comp: "Leisure" },
  { path: routes.CONTACT, comp: "Contact" },
  { path: routes.RESUME, comp: "Resume" },
];
