import { motion } from "framer-motion";
import classNames from "./style.module.scss";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default function MyWorkItem({
  itemPic,
  title,
  tech,
  stack,
  info,
  link,
}) {
  return (
    <a href={link} target="_black">
      <motion.div
        className={classNames.itemWrapper}
        whileHover={{ scale: 1.02 }}
        whileTap={{ scale: 1 }}
        variants={item}
      >
        <motion.div className={classNames.imageWrapper}>
          <img src={itemPic} alt="proj-item" />
        </motion.div>
        <motion.div className={classNames.desc}>
          <p className={`${classNames.title} text-br`}>{title} </p>
          <p className={`${classNames.tech} text-bl`}>🛠️ {tech}</p>
          <p className={`${classNames.info} text-y`}>{info}</p>
        </motion.div>
        <motion.div className={classNames.stack}>
          {stack.map((st) => (
            <span className="text-p" key={st}>
              {st}
            </span>
          ))}
        </motion.div>
        <motion.div></motion.div>
      </motion.div>
    </a>
  );
}
