import { motion } from "framer-motion";
import Page from "../../components/Page";
import { codeLinesEx } from "./codeLines";

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

export default function Experience() {
  return (
    <Page title="Experience">
      {codeLinesEx.exp.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLinesEx.expLog.map((itm, i) => (
        <motion.p className="code-line" key={i} variants={item}>
          {itm}
        </motion.p>
      ))}
      {codeLinesEx.expComnt.map((itm, i) => (
        <motion.p
          className="code-line"
          key={i}
          variants={item}
          style={
            codeLinesEx.expComnt.length - 1 !== i ? { marginBottom: 0 } : {}
          }
        >
          {itm}
        </motion.p>
      ))}
      {codeLinesEx.expComntMore.map((itm, i) => (
        <motion.p
          className="code-line"
          key={i}
          variants={item}
          style={
            codeLinesEx.expComnt.length - 1 !== i ? { marginBottom: 0 } : {}
          }
        >
          {itm}
        </motion.p>
      ))}
      {codeLinesEx.expComntMy.map((itm, i) => (
        <motion.p
          className="code-line"
          key={i}
          variants={item}
          style={
            codeLinesEx.expComnt.length - 1 !== i ? { marginBottom: 0 } : {}
          }
        >
          {itm}
        </motion.p>
      ))}
    </Page>
  );
}
