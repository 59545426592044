
export const codeLines = {
  mySotryLog: [
    <>
      <span className="text-bl">console</span>.
      <span className="text-y">log</span>(
      <span className="text-br"> {`">>> My story:"`}</span>);
    </>,
  ],
  mySotryCmnt: [
    <span className="text-gd">{`// Everyone has a story in their life so do I 🙈. 
    Mine is interesting (at least for me 😏), cause I failed so many times. `}</span>,
    <span className="text-gd">{`// ---- Here is my story ----`}</span>,
    <span className="text-gd">{`// As a kid, I loved technology and innovation like everybody else. 
    But I wanted to learn and make things unlike everybody else.
    So I created my first product Knight Rider (KITT) Scanner LED Light System. 
    I started my first business of selling this to my colleagues. 
    I was a happy kid 😊, but I hated school 😒. I almost failed all subjects.
    But I was passionate about tech ✨.
    So I left school 🏫 three years before my friends.
    And I wanted to learn programming.`}</span>,
  ],
  mySotryCmntMore: [
    <span className="text-gd">
      {`// So I started my under grad at the University of Westminster UK.
    I graduated with a first-class honors 🎓.
    Proud moments? Kidding.., it's just a certification, IDK 😏.
    But it helped me to find a good job in my career at a tech company in Sri Lanka.
    I worked for a tech company that is listed under the top 3 IT companies in Sri Lanka.`}
    </span>,
    <span className="text-gd">
      {`// I was a frontend Software Engineer for this company for more than 3 years.
    And mostly I was working with Norwegian clients.
    But I felt doing something more than Software.
    So I started my own product 💪.
    It is an IoT product.
    And it's a smart lighting tower for gamers and streamers 🎮.
    I left my job and went all in hoping that Covid19 will be gone away in few months 😷.
    I left my job coz I wanted to focus fully on this product.
    `}
    </span>,
    <span className="text-gd">{`//Soo, I completed the prototype of the IoT device and the mobile application in under 3 to 4 months.
    Actual product is currently being developed at Xydder3d.lk.
    But it takes so much time with this Covid19 situation.
    Also hard to find supplies in here at Sri Lanka. Now feels a little stupid 😞💔.
    Anyway parallelly I started to develop a smart collar for cats and dogs as well. 
    Since these things take the time, I thought to work again.`}</span>,
  ],
};
