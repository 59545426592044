import { motion } from "framer-motion";
import Title from "../Title";
import classNames from "./style.module.scss";

const pageTransition = {
  in: {
    opacity: 1,
    y: 0,
  },
  out: {
    opacity: 0,
    y: "-100%",
  },
};

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.2,
      staggerChildren: 0.2,
    },
  },
};
export default function Page({ children, title }) {
  return (
    <motion.div
      className={classNames.wrapper}
      exit="out"
      animate="in"
      initial="out"
      variants={pageTransition}
    >
      <Title title={title} />
      <motion.section
        variants={container}
        initial="hidden"
        animate="visible"
        className={classNames.content}
      >
        {children}
      </motion.section>
    </motion.div>
  );
}
