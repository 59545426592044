import classNames from "./style.module.scss";

export default function Title({ title }) {
  return (
    <div className={classNames.title}>
      <span className="text-tag">&lt;</span>
      <span className="text-comp">{title}</span>
      <span className="text-tag">/&gt;</span>
    </div>
  );
}
